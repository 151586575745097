import React from 'react';

export default ({active}: {active: boolean}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 3H17C17.5523 3 18 3.44772 18 4V7H6V4C6 3.44772 6.44772 3 7 3Z"
      stroke={active ? '#D90000' : '#D9D9D9'}
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6C1.89543 6 1 6.89543 1 8V21C1 22.1046 1.89543 23 3 23H21C22.1046 23 23 22.1046 23 21V8C23 6.89543 22.1046 6 21 6H3ZM3.5 8C3.22386 8 3 8.22386 3 8.5V10.5C3 10.7761 3.22386 11 3.5 11H20.5C20.7761 11 21 10.7761 21 10.5V8.5C21 8.22386 20.7761 8 20.5 8H3.5Z"
      fill={active ? '#D90000' : '#D9D9D9'}
    />
    <rect x="6" y="11" width="2" height="2" fill="white" />
    <rect x="16" y="11" width="2" height="2" fill="white" />
  </svg>
);
